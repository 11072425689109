<template>
  <div class="giga-team_widget giga-margin_t30">
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <div
            class="border-raduis-md bg-white box-shadow-xl-dark p-4"
            style="height: 240px"
        >
          <div
              class="title d-flex justify-content-between align-center"
          >
            <b-skeleton
                variant="light"
                height="30px"
                width="60%"
            ></b-skeleton>

            <b-skeleton
                variant="light"
                height="30px"
                width="48px"
            ></b-skeleton>
          </div>
          <div class="card mt-4">
            <ul class="list-group list-group-flush">
              <li class="list-group-item small">
                <b-skeleton
                    variant="light"
                    height="15px"
                    width="100%"
                ></b-skeleton>
              </li>
              <li class="list-group-item small">
                <b-skeleton
                    variant="light"
                    height="15px"
                    width="100%"
                ></b-skeleton>
              </li>
              <li class="list-group-item small">
                <b-skeleton
                    variant="light"
                    height="15px"
                    width="100%"
                ></b-skeleton>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <div class="giga-widget_header">
        <h3 class="giga-widget_title">{{title}}</h3>
        <b-button :to="{ name: URL }" class="giga-widget_seeMore">Voir tout</b-button>
      </div>
      <div class="giga-widget_body">
        <div v-if="contactLength > 1" class="giga-team_list">
          <template v-for="(contact, index) in getContacts">
            <team-item
                v-if="contact !== null"
                :key="contact.id"
                :firstname="contact.firstName"
                :lastname="contact.lastName"
                :phone="contact.telephone"
                :profession="index"
                :type="contactLength"
            >
            </team-item>
          </template>
        </div>
        <div v-if="contactLength === 1">
          <template v-for="(contact, index) in getContacts">
            <member-card
                v-if="contact !== null"
                :key="contact.id"
                :firstname="contact.firstName"
                :lastname="contact.lastName"
                :phone="contact.telephone"
                :email="contact.mail"
                :fonction="index"
                type="1"
            >
            </member-card>
          </template>
        </div>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>

import teamItem from './items/teamItem.vue'
import {mapActions, mapGetters} from "vuex";
import memberCard from "./items/memberCard";

export default {
  name: "teamWidget",
  components: {teamItem, memberCard },
  props:{
    title:{
      type: String
    },
    URL:{
        type: String
    }
  },
  mounted() {
    this.getContactsAction();
  },
  computed: {
    ...mapGetters('contact', ['getContacts']),
    ...mapGetters('loader', { loading: 'getLoading' }),
    contactLength() {
      let count = 0;
      Object.values(this.getContacts).forEach(c => {
        if (c !== null) count++;
      });
      return count;
    }
  },
  methods: {
    ...mapActions('contact',['getContactsAction']),
  }
};
</script>