<template>
  <div class="giga-member_card">
    <div class="giga-member_item">
      <div class="giga-member_card_header">
        <div class="giga-member_thumb">
          <span></span>
        </div>
        <div class="giga-member_info">
          <h4 class="giga-member_name">{{ fullName }}</h4>
          <p class="giga-member_function">{{ $t(fonction) }}</p>
        </div>
      </div>
      <div>
        <ul class="giga-member_meta_list">
          <li class="giga-member_meta_item">{{ email }}</li>
          <li class="giga-member_meta_item">{{ phone }}</li>
        </ul>
      </div>
      <div class="giga-member_card_footer">
        <div class="giga-button">
            <button class="giga-blue_button" >
              <a :href="'mailto:test@leyton.com'" style="color: #FFF">Envoyer un E-mail</a>
            </button>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "memberCard",
  props: {
    firstname: {
      type: String,
    },
    lastname: {
      type: String,
    },
    email: {
      type: String,
    },
    fonction: {
      type: String,
    },
    phone: {
      type: String,
    },
    type: {
      type: Number,
    },
  },
  computed: {
    fullName() {
      const firstname = this.firstname ? this.firstname : '';
      const lastname= this.lastname ? this.lastname : '';
      return `${firstname}  ${lastname}`;
    }
  }
};
</script>